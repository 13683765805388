import * as React from "react";
const SvgDaemonset = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 68.165, height: 66.143, viewBox: "0 0 18.035 17.5", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z", style: {
  fill: "#326ce5",
  fillOpacity: 1,
  stroke: "none",
  strokeWidth: 0,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "matrix(1.01489 0 0 1.01489 15.91 -3.873)" }), /* @__PURE__ */ React.createElement("path", { d: "M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z", style: {
  color: "#000",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: 400,
  fontStretch: "normal",
  fontSize: "medium",
  lineHeight: "normal",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  textDecorationLine: "none",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  writingMode: "lr-tb",
  direction: "ltr",
  baselineShift: "baseline",
  textAnchor: "start",
  display: "inline",
  overflow: "visible",
  visibility: "visible",
  fill: "#fff",
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
  strokeWidth: 0,
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  marker: "none",
  enableBackground: "accumulate"
}, transform: "matrix(1.01489 0 0 1.01489 15.91 -3.873)" }), /* @__PURE__ */ React.createElement("path", { d: "M7.708 5.283h6.525v4.583H7.708z", style: {
  fill: "none",
  fillRule: "evenodd",
  stroke: "#fff",
  strokeWidth: 0.52914584,
  strokeLinecap: "square",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "1.58743756,1.58743756",
  strokeDashoffset: 3.66698074,
  strokeOpacity: 1
}, transform: "translate(-.406 -.717)" }), /* @__PURE__ */ React.createElement("path", { d: "M4.35 13.607h7.075", style: {
  fill: "none",
  fillRule: "evenodd",
  stroke: "#fff",
  strokeWidth: 0.61833036,
  strokeLinecap: "butt",
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(-.406 -.717)" }), /* @__PURE__ */ React.createElement("path", { d: "M6.17 6.694h6.525v4.583H6.17z", style: {
  fill: "#326ce5",
  fillOpacity: 1,
  fillRule: "evenodd",
  stroke: "#fff",
  strokeWidth: 0.52914584,
  strokeLinecap: "square",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "1.58743756,1.58743756",
  strokeDashoffset: 3.87863898,
  strokeOpacity: 1
}, transform: "translate(-.406 -.717)" }), /* @__PURE__ */ React.createElement("path", { d: "M4.63 8.105h6.526v4.584H4.63z", style: {
  fill: "none",
  fillRule: "evenodd",
  stroke: "#fff",
  strokeWidth: 0.52916664,
  strokeLinecap: "butt",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeOpacity: 1
}, transform: "translate(-.406 -.717)" }), /* @__PURE__ */ React.createElement("path", { d: "M4.587 8.123h6.525v4.583H4.587z", style: {
  fill: "#fff",
  fillRule: "evenodd",
  stroke: "none",
  strokeWidth: 0.26458332,
  strokeLinecap: "square",
  strokeMiterlimit: 10
}, transform: "translate(-.406 -.717)" }));
export default SvgDaemonset;
